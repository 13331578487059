import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./components/standard/standard.routing').then((mod) => mod.STANDARD_ROUTES),
        data: {
            uid: 'startseite'
        }
    },
    {
        path: ':uid',
        loadChildren: () => import('./components/standard/standard.routing').then((mod) => mod.STANDARD_ROUTES)
    },
    {
        path: 'jobs',
        loadChildren: () => import('./components/job/job.routing').then((mod) => mod.JOB_ROUTES)
    }
];
