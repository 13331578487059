import { Injectable } from '@angular/core';
import { GroupField, ImageField, KeyTextField, LinkField, PrismicDocument, RichTextField, TitleField } from '@prismicio/client';
import { BehaviorSubject, from, map } from 'rxjs';
import { environment } from '../../environments/environment';

//TODO: CHECK Document Type and UID and replace <any> with correct type

const GLOBAL_CONTENT = 'global-content';
const GLOBAL_CONTENT_UID = 'global-content';

@Injectable({
    providedIn: 'root'
})
export class GlobalContentService {
    private globalContent = new BehaviorSubject<GlobalContent | undefined>(undefined);

    constructor() {
        this.fetchGlobalContent().subscribe((globalContent) => {
            this.globalContent.next(globalContent);
        });
    }

    getGlobalContent(): BehaviorSubject<any | undefined> {
        return this.globalContent;
    }

    private fetchGlobalContent() {
        return from(environment.prismic.client.getSingle<PrismicDocument<GlobalContent>>(GLOBAL_CONTENT)).pipe(
            map((result) => {
                return result.data;
            })
        );
    }
}

export type GlobalContent = {
    logo: ImageField;
    navigation_menu_items: GroupField<MenuItem>;
    footer_title: TitleField;
    footer_description: RichTextField;
    footer_button_link: LinkField;
    footer_button_text: KeyTextField;
    footer_links_title: KeyTextField;
    footer_links: GroupField<MenuItem>;
    footer_contact_adress: RichTextField;
    footer_contact_phone: RichTextField;
    footer_contact_email: KeyTextField;
    footer_links_legal: GroupField<MenuItem>;
    footer_social_links: GroupField<MenuItem>;
    action_button_icon: ImageField;
    action_button_link: LinkField;
};

export type MenuItem = {
    title: KeyTextField;
    link: LinkField;
};
