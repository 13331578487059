<nav class="navbar navbar-expand-xxl pt-4" [class.opened]="isCollapsed">
    <div class="container">
        <a routerLink="">
            <img [src]="globalContent?.logo?.url" [alt]="globalContent?.logo.alt" [title]="globalContent?.logo.alt" class="logo" />
        </a>
        <button class="navbar-toggler" type="button" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" (click)="collapse()">
            <img [src]="isCollapsed ? '/assets/images/close.svg' : '/assets/images/menu.svg'" alt="Menu Icon" />
        </button>
        <div class="collapse navbar-collapse" id="navbarNav" [class.show]="isCollapsed">
            <ul class="navbar-nav d-flex align-items-center ms-xxl-auto">
                <li class="nav-item mt-5 mt-xxl-0 ms-xxl-5 position-relative" *ngFor="let menuItem of globalContent?.navigation_menu_items">
                    <app-link ngSkipHydration [link]="menuItem.link" routerLinkActive="active-link" (click)="closeMenu()" [cssClass]="">
                        {{ menuItem.title }}
                    </app-link>
                </li>
            </ul>
        </div>
    </div>
</nav>
